// TODO - to be removed, just a POC for iframe communication

import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { createCustomEvent } from "../utils/createCustomEvent"
import { navigate, useLocation } from "@reach/router"
import { PrimaryButton, TextField } from "office-ui-fabric-react"

export const IframeTest = () => {
  const src = "/iframe-target"

  const ref = useRef(null)
  const [message, setMessage] = useState("")
  const location = useLocation()

  // send messages on button click
  const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const event = createCustomEvent({ type: "shiny-message", detail: message })
    ref.current.contentDocument.dispatchEvent(event)
  }

  const listenerHandler = async (event: { data: { message: string } }) => {
    await navigate("?q=" + event.data.message)
  }

  useEffect(() => {
    window.addEventListener("message", listenerHandler, false)

    return () => {
      window.removeEventListener("message", listenerHandler)
    }
  }, [])

  // send messages on route update
  useEffect(() => {
    const locationChangeEvent = createCustomEvent({
      type: "location-change",
      detail: location.href,
    })

    ref.current.contentDocument.dispatchEvent(locationChangeEvent)
  }, [location.href, ref.current])

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setMessage(e.target.value)

  return (
    <>
      <form onSubmit={sendMessage}>
        <TextField value={message} onChange={onInputChange} />
        <PrimaryButton text="Send to iframe" type="submit" allowDisabledFocus />
      </form>

      <iframe src={src} ref={ref} width={"100%"} height={"400px"} />
    </>
  )
}
