type CustomEventInput = {
  detail: string
  type: "shiny-message" | "location-change"
}

export const createCustomEvent = ({ type, detail }: CustomEventInput) => {
  if (!type) {
    throw new Error(
      "custom event requires a type of 'shiny-message' or 'location-change'"
    )
  }

  if (!detail.length) {
    throw new Error("custom event requires a message")
  }

  return new CustomEvent(type, {
    bubbles: false,
    cancelable: false,
    detail,
  })
}
