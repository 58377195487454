import * as React from "react"
import { Router } from "@reach/router"
import Layout from "../components/common/Layout"
import { appBasePath, routes } from "../page-data/routes"
import { Overview } from "../components/overview"
import { IframeTest } from "../components/IframeTest"

const getAppPath = (route: string) => route.replace(appBasePath, "")

const App = () => {
  return (
    <Layout>
      <Router basepath={appBasePath}>
        <Overview default path={getAppPath(routes.dashboard)} />
      </Router>
      <IframeTest />
    </Layout>
  )
}

export default App
